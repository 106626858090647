// NB avoid the temptation to refactor!
// postcss looks for explicitly used class names, so
// these all must appear literally in code
export const SignatureColors = [
    [
        "border-yellow-500",
        "ring-yellow-300",
        "text-yellow-800",
        "bg-yellow-100",
        "bg-yellow-300",
        "border-yellow-800",
        "text-yellow-600",
    ],
    [
        "border-lime-500",
        "ring-lime-300",
        "text-lime-800",
        "bg-lime-100",
        "bg-lime-300",
        "border-lime-800",
        "text-lime-600",
    ],
    [
        "border-blue-500",
        "ring-blue-300",
        "text-blue-800",
        "bg-blue-100",
        "bg-blue-300",
        "border-blue-800",
        "text-blue-600",
    ],
    [
        "border-fuchsia-500",
        "ring-fuchsia-300",
        "text-fuchsia-800",
        "bg-fuchsia-100",
        "bg-fuchsia-300",
        "border-fuchsia-800",
        "text-fuchsia-600",
    ],
    [
        "border-green-500",
        "ring-green-300",
        "text-green-800",
        "bg-green-100",
        "bg-green-300",
        "border-green-800",
        "text-green-600",
    ],
    [
        "border-purple-500",
        "ring-purple-300",
        "text-purple-800",
        "bg-purple-100",
        "bg-purple-300",
        "border-purple-800",
        "text-purple-600",
    ],
    [
        "border-cyan-500",
        "ring-cyan-300",
        "text-cyan-800",
        "bg-cyan-100",
        "bg-cyan-300",
        "border-cyan-800",
        "text-cyan-600",
    ],
    [
        "border-teal-500",
        "ring-teal-300",
        "text-teal-800",
        "bg-teal-100",
        "bg-teal-300",
        "border-teal-800",
        "text-teal-600",
    ],
    [
        "border-emerald-500",
        "ring-emerald-300",
        "text-emerald-800",
        "bg-emerald-100",
        "bg-emerald-300",
        "border-emerald-800",
        "text-emerald-600",
    ],

    [
        "border-amber-500",
        "ring-amber-300",
        "text-amber-800",
        "bg-amber-100",
        "bg-amber-300",
        "border-amber-800",
        "text-amber-600",
    ],
    [
        "border-orange-500",
        "ring-orange-300",
        "text-orange-800",
        "bg-orange-100",
        "bg-orange-300",
        "border-orange-800",
        "text-orange-600",
    ],
    [
        "border-pink-500",
        "ring-pink-300",
        "text-pink-800",
        "bg-pink-100",
        "bg-pink-300",
        "border-pink-800",
        "text-pink-600",
    ],
    [
        "border-indigo-500",
        "ring-indigo-300",
        "text-indigo-800",
        "bg-indigo-100",
        "bg-indigo-300",
        "border-indigo-800",
        "text-indigo-600",
    ],
];

export const getSignatureBorderStyle = (index: number) => {
    return SignatureColors[index % SignatureColors.length][0];
};

export const getSelectedSignatureBorderStyle = (index: number) => {
    return SignatureColors[index % SignatureColors.length][5];
};

export const getSignaturePlaceholderStyle = (index: number) => {
    return `${SignatureColors[index % SignatureColors.length][2]}`;
};

export const getSignatureRingStyle = (index: number) => {
    return `${SignatureColors[index % SignatureColors.length][1]} ring-2 ring-offset-2`;
};

export const getSignatureLinkStyle = (index: number) => {
    return `${
        SignatureColors[index % SignatureColors.length][2]
    } hover:underline twoverride`;
};

export const getSignatureBackgroundStyle = (index: number) => {
    return `${
        SignatureColors[index % SignatureColors.length][3]
    } rounded-lg p-2 lg:p-4 space-y-4 shadow`;
};

export const getInlineSignatureBackgroundStyle = (index: number) => {
    return `${SignatureColors[index % SignatureColors.length][3]} p-2 lg:p-4 space-y-4`;
};

export const getSignatureSelectedFieldBackgroundStyle = (index: number) => {
    return `${
        SignatureColors[index % SignatureColors.length][4]
    } py-2 px-4 -mx-4 space-y-4`;
};

export const getSignExperienceBackgroundStyle = (index: number) => {
    return `${SignatureColors[index % SignatureColors.length][4]}`;
};

export const getSignatureLinkMetadataStyle = (index: number) => {
    return `${SignatureColors[index % SignatureColors.length][6]}`;
};
