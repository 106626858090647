import React from "react";

interface PersonaProps {
    initialsOnly?: boolean;
    name: string;
    size: "small" | "large" | "auto";
}

const colors = [
    "bg-blue-700 text-gray-50",
    "bg-blue-300 text-gray-700",
    "bg-green-700 text-gray-50",
    "bg-green-300 text-gray-700",
    "bg-indigo-700 text-gray-50",
    "bg-indigo-300 text-gray-700",
    "bg-pink-700 text-gray-50",
    "bg-pink-300 text-gray-700",
    "bg-purple-700 text-gray-50",
    "bg-purple-300 text-gray-700",
    "bg-red-700 text-gray-50",
    "bg-red-300 text-gray-700",
    "bg-yellow-700 text-gray-50",
    "bg-yellow-300 text-gray-700",
    "bg-rose-700 text-gray-50",
    "bg-rose-300 text-gray-700",
    "bg-orange-700 text-gray-50",
    "bg-orange-300 text-gray-700",
    "bg-teal-700 text-gray-50",
    "bg-teal-300 text-gray-700",
    "bg-cyan-700 text-gray-50",
    "bg-cyan-300 text-gray-700",
];

const classes = {
    auto: {
        initials: "w-7 h-7 text-sm md:w-10 md:h-10 md:text-lg",
        name: "text-sm md:text-lg",
        space: "space-x-1 md:space-x-2",
    },
    small: { initials: "w-7 h-7 text-sm", name: "text-sm", space: "space-x-1" },
    large: { initials: "w-10 h-10 text-lg", name: "text-lg", space: "space-x-2" },
};

export const CrmPersona: React.FC<PersonaProps> = props => {
    const { initialsOnly, name, size } = props;
    const initials = React.useMemo(() => getInitials(name), [name]);
    const swatch = React.useMemo(() => getSwatch(name), [name]);

    if (!name) {
        return <div />;
    }

    return (
        <div className={`${classes[size]["space"]} flex items-center`}>
            <div
                className={`${classes[size]["initials"]} ${swatch} rounded-full font-bold flex items-center justify-around`}>
                <div>{initials}</div>
            </div>
            {initialsOnly ? null : <div className={classes[size]["name"]}>{name}</div>}
        </div>
    );
};

const getSwatch = (name: string | undefined): string => {
    if (!name || name === "") {
        return colors[0];
    }

    let hash = 0;
    for (let i: number = name.length - 1; i >= 0; i--) {
        const charAt: number = name.charCodeAt(i);
        const shift: number = i % 8;
        hash ^= (charAt << shift) + (charAt >> (8 - shift));
    }

    return colors[hash % colors.length];
};

export const getInitials = (input: string | undefined | null): string => {
    if (!input) return "";
    if (input === "") return "";
    const terms = input.split(" ");
    if (terms.length === 1) {
        return terms[0].charAt(0).toUpperCase();
    }
    return (
        terms[0].charAt(0).toUpperCase() + terms[terms.length - 1].charAt(0).toUpperCase()
    );
};
