import {
    CalendarDaysIcon,
    CheckCircleIcon,
    DocumentTextIcon,
    HashtagIcon,
    PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { SignatureFieldType } from "generated/graphql";
import React from "react";
import { getSignaturePlaceholderStyle } from "./signature-colors";

interface SignatureFieldIconProps {
    fieldType: SignatureFieldType;
    recipientIndex: number;
}

export const SignatureFieldIcon: React.FC<SignatureFieldIconProps> = props => {
    const { fieldType, recipientIndex } = props;

    const placeholderClassName = getSignaturePlaceholderStyle(recipientIndex);

    if (fieldType === SignatureFieldType.Text) {
        return <DocumentTextIcon className={`w-4 h-4 ${placeholderClassName}`} />;
    }

    if (fieldType === SignatureFieldType.Signature) {
        return <PencilSquareIcon className={`w-4 h-4 ${placeholderClassName}`} />;
    }

    if (fieldType === SignatureFieldType.Initial) {
        return <HashtagIcon className={`w-4 h-4 ${placeholderClassName}`} />;
    }

    if (fieldType === SignatureFieldType.Checkbox) {
        return <CheckCircleIcon className={`w-4 h-4 ${placeholderClassName}`} />;
    }

    if (fieldType === SignatureFieldType.Date) {
        return <CalendarDaysIcon className={`w-4 h-4 ${placeholderClassName}`} />;
    }

    return null;
};
